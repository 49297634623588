.daily-promo-and-products {
    &.PromoWithinSlider {
        .daily-promo-and-products__content {
            @include desktop {
                align-items: flex-start;
            }
        }
    }

    &__content {
        .DailyPromoProduct {
            @include from-tablet {
                align-items: center;
            }
        }
    }
}
