@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.AdvoxSlider {
    --slider-arrow-background: #{$default-primary-base-color};
    &_variant_default .AdvoxSlider-Heading,
    &-Heading {
        color: $purple80;
        width: max-content;
        font-weight: 600;
        z-index: 0;

        &:before {
            content: '';
            width: 80px;
            height: 80px;
            background: $orange5;
            position: absolute;
            top: -15px;
            left: -25px;
            z-index: -1;
            border-radius: 5px 28px;
        }

        &_headingSide_center {
            margin: 10px auto;
        }
    }
    .slick-arrow {
        filter: drop-shadow(0px 10px 15px rgba(63, 60, 234, 0.15));
    }
    &_variant_products {
        border-top: 1px solid rgba(91, 69, 177, 0.1);
        padding-top: 81px;
        @include mobile {
            margin: 50px auto;
        }
    }
    &_variant_hero {
        --slider-dots-offset: 20px;
        --slider-arrow-offset: 15px;
        --slider-max-width: calc(100% + 28px);
        --slider-height: 260px;
        --slider-height-tablet: 465px;
        --slider-height-desktop: 580px;
        @include mobile {
            margin-top: 20px;
        }
        @include tablet {
            margin-top: 35px;
        }
        .slick-slider .slick-track,
        .slick-slider .slick-list {
            overflow-y: auto;
        }

        .slick-slider {
            height: 100%;
            overflow-y: visible;
            margin: 0 auto;
        }

        .slick-dots {
            position: absolute;
            top: calc(var(--slider-height) + var(--slider-dots-offset));
            height: 13px;
            @include widest-desktop {
                height: unset;
                bottom: unset;
                top: 50%;
                transform: translateY(calc(-50% - 15px));
                right: 15px;
                left: unset;
                width: 12px;
            }
            li {
                background: $default-primary-base-color;
                @include wider-desktop {
                    display: inline-block;
                    margin: 0 5px;
                }
                @include large-screen {
                    display: block;
                    margin: 8px 0;
                }
                &.slick-active {
                    background: $default-primary-base-color;
                }
            }
            @include from-tablet {
                top: calc(var(--slider-height-tablet) + var(--slider-dots-offset));
            }

            @include desktop {
                top: calc(var(--slider-height-desktop) - var(--slider-dots-offset) - 10px);
            }
        }
        .slick-arrow {
            top: calc((var(--slider-height) / 2));
            transform: translateY(-50%);
            background: $default-primary-base-color;
            width: 32px;
            height: 48px;
            filter: drop-shadow(0px 10px 15px rgba(63, 60, 234, 0.15));
            z-index: 9;

            @include from-tablet {
                border-radius: 100%;
                width: 40px;
                height: 40px;
                top: calc((var(--slider-height-tablet) / 2));
            }

            @include desktop {
                top: calc((var(--slider-height-desktop) / 2));
            }

            &:before {
                display: none;
            }

            &:hover {
                background: $default-primary-base-color;
            }

            svg {
                position: relative;
                fill: $white;
                width: 13px;

                @include from-tablet {
                    top: -5px;
                }
            }
        }
        .slick-prev {
            left: -15px;
            border-radius: 0 24px 24px 0;

            @include from-tablet {
                border-radius: 100%;
            }

            @include widest-desktop {
                left: calc((100% - var(--content-wrapper-width)) / 3 - 2px);
            }

            svg {
                right: -5px;

                @include from-tablet {
                    right: -13px;
                }
            }
        }
        .slick-next {
            right: -15px;
            border-radius: 24px 0 0 24px;

            @include from-tablet {
                border-radius: 100%;
            }

            @include widest-desktop {
                right: calc((100% - var(--content-wrapper-width)) / 3 - 2px);
            }

            svg {
                left: 13px;
            }
        }
    }
}
