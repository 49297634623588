@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

$select-width: 160px !default;
$select-height: 45px !default;
$select-option-height: 36px !default;
$select-arrow-height: 14px !default;
$select-arrow-width: 14px !default;

:root {
    --select-color-default: #{$default-secondary-base-color};
    --input-background-color: #{$grey-light};
    --select-color-hover: #{$default-secondary-dark-color};
    --select-color-disabled: #{$grey};
    --select-font-size: 15px;
    --select-border-radius: 10px;
    --select-border-radius-expanded: 10px;
}

[dir='ltr'] .Field_type_select .FieldSelect {
    cursor: pointer;
    border-radius: var(--select-border-radius);
    min-width: 216px;
    &:hover,
    &:focus {
        select {
            border-color: var(--select-color-hover);
            color: var(--select-color-hover);
        }
        .ChevronIcon {
            fill: var(--select-color-hover);
        }
    }
    &_isExpanded {
        &:hover,
        &:focus {
            select {
                border-color: var(--select-color-default);
                color: var(--select-color-default);
            }
            .ChevronIcon {
                fill: var(--select-color-default);
            }
        }
    }
    &-Select {
        position: relative;
        width: 100%;
        pointer-events: none;
        border-radius: var(--select-border-radius);
        padding: 16px 60px 18px 32px;
        font-family: inherit;
        font-weight: 500;
        font-size: var(--select-font-size);
        line-height: 22px;
        letter-spacing: -0.4px;
        color: $black;
        background-color: var(--input-background-color);
        text-overflow: ellipsis;

        &_isExpanded {
            border-bottom: 0;
            border-radius: var(--select-border-radius-expanded) var(--select-border-radius-expanded) 0 0;
            background-color: $white;
            --input-border-color: var(--select-color-default);
        }

        &_isPlaceholder {
            color: var(--select-color-disabled);
        }

        &:disabled {
            color: var(--select-color-disabled);
            border-color: var(--select-color-disabled);
            + svg {
                fill: var(--select-color-disabled);
            }
        }
    }
    .ChevronIcon {
        display: inline-block;
        width: $select-arrow-width;
        height: $select-arrow-height;
        pointer-events: none;
        position: absolute;
        inset-inline-end: 12px;
        right: 32px;
        fill: var(--input-color);
    }

    &-Clickable {
        width: 100%;
        display: flex;
        align-items: center;
        user-select: none;
    }
    &-Options {
        position: absolute;
        width: 100%;
        inset-block-start: 100%;
        inset-inline-start: 0;
        z-index: 40;
        border-width: 0 1px 1px;
        border-style: solid;
        border-color: transparent;
        overflow-y: scroll;
        transition: 200ms max-height;
        will-change: max-height;
        max-height: 0;
        -webkit-overflow-scrolling: touch;
        min-width: 100%;
        background-color: $white;
        visibility: hidden;

        // Firefox support
        scrollbar-width: none !important;

        &::-webkit-scrollbar {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            display: none;
        }

        &_isExpanded {
            border-radius: 0 0 var(--select-border-radius-expanded) var(--select-border-radius-expanded);
            border-color: var(--select-color-default);
            border-top: 0;
            max-height: 250px;
            visibility: visible;
        }
    }

    &-Option {
        z-index: 1;
        transition: 100ms max-height ease-in;
        will-change: max-height;
        max-height: unset;
        padding-inline-start: 18px;
        color: var(--select-color-disabled);
        padding: 7px 32px;
        font-family: var(--font-text-gyre-adventor);
        font-style: normal;
        font-weight: 600;
        font-size: var(--select-font-size);
        line-height: 22px;
        letter-spacing: -0.4px;
        background: #ffffff;
        margin: 0;
        text-align: left;
        &:before {
            display: none;
        }
        &:first-of-type {
            padding: 0 32px 7px;
        }
        &:last-of-type {
            margin-bottom: 15px;
        }
        &:hover,
        &:focus {
            background-color: $white;
            color: var(--select-color-default);
        }
    }
}
