@font-face {
    font-family: 'Palanquin';
    src: url(../../../../advox-strigo-b2c/src/style/fonts/palanquin-regular-webfont.woff2) format('woff2'),
        url(../../../../advox-strigo-b2c/src/style/fonts/palanquin-regular-webfont.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Palanquin';
    src: url(../../../../advox-strigo-b2c/src/style/fonts/palanquin-semibold-webfont.woff2) format('woff2'),
        url(../../../../advox-strigo-b2c/src/style/fonts/palanquin-semibold-webfont.woff) format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Palanquin';
    src: url(../../../../advox-strigo-b2c/src/style/fonts/palanquin-bold-webfont.woff2) format('woff2'),
        url(../../../../advox-strigo-b2c/src/style/fonts/palanquin-bold-webfont.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../../../../advox-strigo-b2c/src/style/fonts/roboto-regular-webfont.woff2) format('woff2'),
        url(../../../../advox-strigo-b2c/src/style/fonts/roboto-regular-webfont.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../../../../advox-strigo-b2c/src/style/fonts/roboto-bold-webfont.woff2) format('woff2'),
        url(../../../../advox-strigo-b2c/src/style/fonts/roboto-bold-webfont.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}

:root {
    --font-palanquin: 'Palanquin', sans-serif;
    --font-roboto: 'Roboto', sans-serif;
    --primary-font-weight-normal: 600;
    --primary-font-weight-bold: 700;
    --secondary-font-weight-normal: 400;
    --secondary-font-weight-bold: 700;
}

body {
    font-family: var(--font-roboto);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-palanquin);
}
