@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --slider-crumb-color: #{$orange5};
    --slider-crumb-active-color: #{$default-secondary-base-color};
}

.Slider {
    &-Crumbs {
        bottom: -30px;
    }

    &-Crumb {
        width: 12px;
        height: 12px;
        background-color: var(--slider-crumb-color);

        &_isActive {
            background-color: var(--slider-crumb-active-color);
        }
    }
}
