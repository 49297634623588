@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --footer-background-color: #{$white};
    --footer-border: 1px solid rgba(91, 69, 177, 0.1);
}

.Footer {
    &-CopyrightContentWrapper,
    &-Content {
        background-color: var(--footer-background-color);
    }

    &-Columns {
        display: block;
        height: 100%;
    }

    &-Bottom {
        @include from-tablet {
            margin-top: 50px;
        }
        @include desktop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: var(--footer-border);
        }
    }

    &-PaymentIcons {
        width: 100%;
        padding: 40px 10px;
        border-bottom: var(--footer-border);

        @include from-tablet {
            padding: 40px 0;
            border-top: var(--footer-border);
        }

        @include desktop {
            border: none;
        }

        ul {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 40px;
            @include desktop {
                justify-content: flex-start;
            }

            svg {
                width: 50px;
                cursor: default;

                &:not(.Icon_grayscale) {
                    filter: grayscale(1);
                    opacity: 0.5;
                }
            }
        }
    }

    &-StoreSwitcher {
        padding: 40px 10px 40px;
        width: 100%;
        @include from-tablet {
            padding: 40px 0 60px;
        }

        .StoreSwitcher-StoreListWithoutSelect {
            justify-content: center;
            @include desktop {
                justify-content: flex-end;
            }
        }
    }
}
