@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --brands-background: #{$grey-light};
    --brand-item-text-color: #{$default-primary-base-color};
    --brand-item-background: #{$white};
    --brand-item-radius: 10px;
    --brand-item-font-family: #{$font-primary};
    --brand-item-size-s: 150px;
    --brand-item-size-m: 200px;
    --brand-item-size-l: 209px;
}
.Brands {
    background: var(--brands-background);
    padding: 45px 0;
    overflow-x: hidden;

    .AdvoxSlider {
        &-Heading {
            @include from-tablet {
                margin: 10px auto 50px;
            }
        }
        .slick-prev {
            @include widest-desktop {
                left: -70px;
            }
        }
        .slick-next {
            @include widest-desktop {
                right: -70px;
            }
        }

        .Brands-ImgWrapper {
            min-width: var(--brand-item-size-s);
            min-height: var(--brand-item-size-s);
            margin-bottom: 25px;

            @include from-tablet {
                min-width: var(--brand-item-size-m);
                min-height: var(--brand-item-size-m);
            }

            @include widest-desktop {
                min-width: var(--brand-item-size-l);
                min-height: var(--brand-item-size-l);
            }
        }
    }
    &-Grid {
        display: grid;

        @include desktop {
            grid-template-columns: repeat(6, 1fr);
            grid-column-gap: 24px;
            grid-row-gap: 64px;
        }

        @include tablet {
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 50px;
        }

        @include mobile {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 24px;
            grid-row-gap: 40px;
        }

        .Brands-ImgWrapper {
            margin-bottom: 10px;

            @include from-tablet {
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    padding-bottom: 100%;
                }
            }

            @include mobile {
                min-height: var(--brand-item-size-s);
            }
        }
    }
    &-Item {
        text-align: center;
        a {
            color: var(--brand-item-text-color);
            font-family: var(--brand-item-font-family);
            font-weight: bold;
            font-size: 15px;
            line-height: 140%;
            letter-spacing: 0.07em;

            @include from-tablet {
                font-size: 22px;
                line-height: 135%;
            }
        }
    }
    &-ImgWrapper {
        border-radius: var(--brand-item-radius);
        background: var(--brand-item-background);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        img {
            max-width: 130px;
            object-fit: contain;
            @include mobile {
                max-height: 118px;
            }
            @include from-tablet {
                max-width: 168px;
                max-height: 168px;
            }
        }
    }

    .slick-list {
        @include tablet {
            // needed important for overriding slick styles
            padding: 0 115% 0 0 !important;
        }
    }
}
