@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

.CarouselScrollItem {
    margin-left: 0 !important;
    width: 70px;
    height: 80px;
    border: 0;

    & + .CarouselScrollItem {
        margin-top: var(--carousel-scroll-gap);
    }

    .ProductGalleryThumbnailImage {
        padding: 0;

        .ProductGalleryThumbnailImage {
            &.Image {
                height: 80px;
            }
        }
    }
}
