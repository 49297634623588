@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.ForgotPassword {
    h3 {
        color: $default-primary-base-color;
    }

    &-InnerWrapper .Button:not(.Button_likeLink) {
        --button-border-radius: 100px;
        --button-hover-border-radius: 100px;
    }
}
