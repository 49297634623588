@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
}

[dir='ltr'] .CurrencySwitcher {
    .FieldSelect {
        &-Select {
            @include desktop {
                --store-switcher-color: #{$default-primary-base-color};
            }
        }

        .ChevronIcon {
            @include desktop {
                --store-switcher-color: #{$default-primary-base-color};
            }
        }
    }
}
