.homepage-category-tiles {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: var(--content-wrapper-width);
    padding-left: var(--content-padding);
    padding-right: var(--content-padding);
    margin: 5px auto 0 auto;
    padding-bottom: 90px;

    @include mobileAndTablet {
        margin-top: 0;
        flex-wrap: wrap;
        max-width: 665px;
        padding-top: 81px;
        padding-bottom: 13px;
        position: static;
    }

    @include mobile {
        max-width: 367px;
    }

    &::before {
        content: '';
        display: block;
        height: 1px;
        background-color: $purple50;
        opacity: 0.1;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin-left: var(--content-padding);
        margin-right: var(--content-padding);

        @include mobileAndTablet {
            margin: 0;
        }
    }

    a {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        width: auto;

        @include mobileAndTablet {
            margin-bottom: 67px;
        }

        &:hover {
            img {
                transform: translateY(-5px);
            }
        }

        &:not(:last-of-type) {
            margin-right: 6.5%;

            @include mobileAndTablet {
                margin-right: 0;
            }

            @include mobile {
                width: 48%;

                img {
                    height: 50vw;
                    max-height: 180px;
                }
            }
        }

        &:last-of-type {
            @include mobileAndTablet {
                width: 100%;
            }
        }
    }

    img {
        width: auto;
        max-width: 100%;
        transition: transform 0.3s ease-in-out;
        height: auto;
    }

    span {
        font-family: $font-primary;
        font-style: normal;
        color: $purple80;
        font-weight: 600;
        font-size: 22px;
        line-height: 1.36;
        margin-top: 35px;
        margin-bottom: 0;
        width: 100%;
        text-align: center;

        @include mobile {
            margin-top: 17px;
        }

        strong {
            color: $orange50;
        }
    }
}