@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.HeroSlider {
    &-Wrapper {
        background: linear-gradient(0, rgba(107, 69, 188, 0.045) 0%, rgba(171, 224, 193, 0) 100%);
        padding: 0 15px;
    }

    &-Slide {
        position: relative;
        white-space: nowrap;

        @include from-tablet {
            padding: 0 80px;
        }

        &_clickable {
            cursor: pointer;
        }

        @include desktop {
            display: flex;
            align-items: center;
            max-width: var(--content-wrapper-width);
            margin: 0 auto;
        }
    }

    &-SlideImage {
        object-fit: contain;
        width: 100%;
        height: var(--slider-height);

        @include from-tablet {
            height: var(--slider-height-tablet);
        }

        @include desktop {
            height: var(--slider-height-desktop);
        }
    }

    &-SlideContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-width: 100%;
        margin-top: 50px;
        position: relative;
        top: 10px;
        padding: 0 25px;

        @include mobile {
            top: -20px;
            margin-top: 80px;
        }

        @include desktop {
            margin: 0 15px;
        }

        @include wide-desktop {
            margin: 0 35px 0 70px;
        }
    }

    &-SlideContentText {
        top: -20px;
        width: 100%;
        font-family: $font-secondary;
        font-size: 15px;
        line-height: 150%;
        color: $grey-dark;
    }

    &-SlideHeader {
        font-family: $font-primary;
        font-weight: 700;
        font-size: 36px;
        line-height: 125%;
        color: $purple80;
        letter-spacing: -1px;
        z-index: 0;
        padding-right: 30px;

        @include from-tablet {
            font-size: 56px;
            line-height: 62px;
            min-width: 411px;
        }

        @include wider-desktop {
            min-width: 511px;
        }

        span {
            color: $default-secondary-base-color;
        }

        &:before {
            content: '';
            width: 64px;
            height: 64px;
            background: $orange5;
            position: absolute;
            top: -9px;
            left: -25px;
            z-index: -1;
            border-radius: 5px 28px;

            @include from-tablet {
                top: -30px;
                left: -38px;
                width: 128px;
                height: 128px;
                border-radius: 10px 56px;
            }
        }
    }

    &-SlideDescription {
        font-family: $font-secondary;
        font-size: 15px;
        line-height: 150%;
        color: $grey-dark;
        margin-top: 20px;

        @include desktop {
            max-width: 465px;
        }
    }

    &-SlideHeader,
    &-SlideDescription {
        white-space: normal;
    }

    &-CTAWrapper {
        width: 100%;
        @include tablet {
            margin-bottom: 40px;
        }
    }

    &-CTAButtonWrapper {
        max-width: 183px;

        &_position {
            &_center {
                justify-content: center;
            }

            &_right {
                justify-content: flex-end;
            }
        }
    }

    &-CTAButton {
        white-space: nowrap;
        min-height: 56px;

        &:hover,
        &:focus {
            background-color: var(--button-hover-background);
            border-color: var(--button-hover-border);
            color: var(--button-hover-color);
        }
    }
}
