:root {
    --table-body-border: 1px solid #{$grey5};
    --table-head-background: #{$grey-light};
    --table-head-color: #{$default-primary-base-color};
    --table-body-font-family: #{$font-secondary};
    --table-head-radius: 7px;
    --table-head-padding: 13px 23px;
    --table-font-size: 15px;
}

table {
    thead th {
        color: var(--table-head-color);
        background-color: var(--table-head-background);
        border-radius: var(--table-head-radius);
    }

    tbody {
        font-family: var(--table-body-font-family);

        th {
            color: var(--my-account-table-head-color);
        }
    }

    tbody tr:not(:last-child) {
        border-block-end: var(--table-body-border);
    }

    td,
    th {
        padding: 10px;
        text-align: start;
        min-width: 120px;

        @include mobile {
            min-width: 140px;
        }
    }

    th,
    td {
        padding: var(--table-head-padding);
        word-break: break-all;
        font-size: var(--table-font-size);

        @include mobile {
            --table-font-size: 14px;
            --table-head-padding: 10px;
        }
    }
    th {
        font-family: $font-primary;
        letter-spacing: 0.07em;
        font-weight: bold;
        line-height: 140%;
    }
    td {
        font-family: $font-secondary;
        font-weight: normal;
        line-height: 150%;
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: auto;
}
