@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}
.ExpandableContent {
    &-Content {
        @include mobileAndTablet {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
        }

        &_isContentExpanded {
            @include mobileAndTablet {
                @include expanded-content;
            }
        }

        &_isExpandableOnDesktop {
            max-height: 0;
            overflow: hidden;
            opacity: 0;

            &.ExpandableContent-Content_isContentExpanded {
                @include expanded-content;
            }
        }
    }
}
