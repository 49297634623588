:root {
    --default-button-padding: 20px;
    --default-button-radius: 100px;
    --default-button-height: 56px;
    --default-text-decoration: none;
    --button-border-width: 2px;
    --button-disabled-color: #{$grey};
    --button-disabled-background: #{$grey-light};
    // Primary button
    --button-background: var(--secondary-base-color);
    --button-border: var(--secondary-base-color);
    --button-border-radius: var(--imported_buttons_border_radius, var(--default-button-radius));
    --button-color: var(--imported_buttons_color, #{$white});
    --button-height: var(--imported_buttons_height, var(--default-button-height));
    --button-padding: var(--imported_buttons_padding, var(--default-button-padding));
    --button-text-decoration: var(--imported_buttons_text_decoration, var(--default-text-decoration));
    --button-box-shadow: 0 10px 15px rgba(170, 115, 76, 0.35);
    // Primary button (Hover)
    --button-hover-background: var(--imported_hoverButtons_background_color, var(--secondary-dark-color));
    --button-hover-border: var(--imported_hoverButtons_border_color, var(--secondary-dark-color));
    --button-hover-border-radius: var(--imported_hoverButtons_border_radius, var(--default-button-radius));
    --button-hover-color: var(--imported_hoverButtons_color, #{$white});
    --button-hover-height: var(--imported_hoverButtons_height, var(--default-button-height));
    --button-hover-padding: var(--imported_hoverButtons_padding, var(--default-button-padding));
    --button-hover-decoration: var(--imported_hoverButtons_text_decoration, var(--default-text-decoration));
    // Secondary button ( )
    --secondary-button-background: var(--primary-base-color);
    --secondary-button-border: var(--primary-base-color);
    --secondary-button-box-shadow: 0 10px 15px rgba(63, 60, 234, 0.15);
    // Secondary button (Hover)
    --secondary-button-hover-background: var(--primary-dark-color);
    --secondary-button-hover-border: var(--primary-dark-color);
    // Tertiary button ( )
    --tertiary-button-background: transparent;
    --tertiary-button-border: #{$white};
    --tertiary-button-border-radius: var(--imported_hollowButtons_border_radius, var(--default-button-radius));
    --tertiary-button-color: #{$white};
    --tertiary-button-height: var(--imported_hollowButtons_height, var(--default-button-height));
    --tertiary-button-padding: var(--imported_hollowButtons_padding, var(--default-button-padding));
    --tertiary-button-text-decoration: var(--imported_hollowButtons_text_decoration, var(--default-text-decoration));
    // Tertiary button (Hover)
    --tertiary-button-hover-background: #{$white};
    --tertiary-button-hover-border: #{$white};
    --tertiary-button-hover-border-radius: var(
        --imported_hollowHoverButtons_border_radius,
        var(--default-button-radius)
    );
    --tertiary-button-hover-color: var(--imported_hollowHoverButtons_color, var(--primary-dark-color));
    --tertiary-button-hover-height: var(--imported_hollowHoverButtons_height, var(--default-button-height));
    --tertiary-button-hover-padding: var(--imported_hollowHoverButtons_padding, var(--default-button-padding));
    --tertiary-button-hover-text-decoration: var(
        --imported_hollowHoverButtons_text_decoration,
        var(--default-text-decoration)
    );

    // Hollow button (Hollow)
    --hollow-button-background: var(--imported_hollowButtons_background_color, tranparent);
    --hollow-button-border: var(--imported_hollowButtons_border_color, var(--primary-base-color));
    --hollow-button-border-radius: var(--imported_hollowButtons_border_radius, var(--default-button-radius));
    --hollow-button-color: var(--imported_hollowButtons_color, var(--primary-base-color));
    --hollow-button-height: var(--imported_hollowButtons_height, var(--default-button-height));
    --hollow-button-padding: var(--imported_hollowButtons_padding, var(--default-button-padding));
    --hollow-button-text-decoration: var(--imported_hollowButtons_text_decoration, var(--default-text-decoration));
    // Hollow button (Hover)
    --hollow-button-hover-background: var(--imported_hollowHoverButtons_background_color, tranparent);
    --hollow-button-hover-border: var(--imported_hollowHoverButtons_border_color, var(--primary-dark-color));
    --hollow-button-hover-border-radius: var(--imported_hollowHoverButtons_border_radius, var(--default-button-radius));
    --hollow-button-hover-color: var(--imported_hollowHoverButtons_color, var(--primary-dark-color));
    --hollow-button-hover-height: var(--imported_hollowHoverButtons_height, var(--default-button-height));
    --hollow-button-hover-padding: var(--imported_hollowHoverButtons_padding, var(--default-button-padding));
    --hollow-button-hover-text-decoration: var(
        --imported_hollowHoverButtons_text_decoration,
        var(--default-text-decoration)
    );
    --button-height-small: 40px;
    --button-hover-height-small: 40px;
}

.Button {
    @include button;
}

button {
    background-color: transparent;
}
