@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --popup-header-background: #{$default-primary-base-color};

    --popup-close-btn-border-radius: 100%;
    --popup-close-btn-color: #{$default-primary-base-color};

    @include mobileAndTablet {
        --popup-close-btn-color: #{$purple90};
    }
}
