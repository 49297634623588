@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --search-field-color-placeholder: #{$purple30};
    --search-field-color: #{$default-primary-base-color};
    --search-input-desktop-border-color: #{$purple20};
}

.SearchField {
    &-Input {
        background: $white;
        color: var(--search-field-color);

        &::placeholder {
            color: var(--search-field-color-placeholder);
        }
        &:focus {
            border-color: var(--search-field-color);
        }
    }
}
