@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --breadcrumbs-color: #{$grey-dark};
}

.Breadcrumbs {
    @include mobile {
        display: block;
    }

    &-List {
        @include desktop {
            padding: 28px 0 22px;
        }

        @include tablet {
            padding: 16px 0 10px;
        }

        @include mobile {
            padding: 12px 0 6px;
        }

        li:last-child {
            margin-bottom: 6px;
        }
    }
}
