@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --menu-border-radius: 25px;
    --menu-item-caption-parent-color: #{$white};
    --menu-item-caption-parent-hover-color: #{$white};
    --menu-item-caption-special-color: #{$red-light2};
    --menu-item-caption-parent-mobile-color: #{$default-secondary-base-color};
    --menu-item-caption-parent-mobile-background: #{$default-primary-base-color};
    --subcategory-border-color: transparent;
    --subcategory-box-shadow-color: rgba(63, 60, 234, 0.15);
    --menu-overlay-background: #{$purple80};
    --menu-overlay-opacity: 0.6;
    --mobile-menu-item-border: #{$purple10};
}

.Menu {
    &-Item {
        @include desktop {
            height: auto;
            display: flex;
            margin: 0 25px;
            padding: 0;

            &:last-child {
                margin-bottom: 0;
            }

            &_isHovered {
                &:before {
                    top: -25px;
                    height: calc(100% + 50px);
                    box-shadow: 0 0 15px 0 var(--subcategory-box-shadow-color);
                    z-index: 1;
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: -20px;
                    top: -20px;
                    width: calc(100% + 40px);
                    height: calc(100% + 60px);
                }
            }
        }

        @include mobileAndTablet {
            border-bottom: 1px solid var(--mobile-menu-item-border);
        }
    }

    &-ItemCaption {
        &_type_main {
            @include mobileAndTablet {
                .Menu-ExpandedState {
                    opacity: 0;
                }

                &.Menu-ItemCaption_isExpanded {
                    .Menu-ExpandedState {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &-ItemList {
        &_type {
            &_main {
                @include desktop {
                    .Menu-Link {
                        &_isParent {
                            &.Menu-Link_isHovered {
                                background: $default-secondary-base-color;
                                border-color: $default-secondary-base-color;
                            }
                        }
                    }
                }
            }
        }
    }

    &-SubCategoriesWrapper {
        @include desktop {
            box-shadow: 0 5px 15px var(--subcategory-box-shadow-color);
        }
    }
}
