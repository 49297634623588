h1 {
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.03em;
    margin-bottom: 48px;
}

h2 {
    font-size: 56px;
    line-height: 62px;
    margin-bottom: 40px;
}

h3 {
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 32px;
}

h4 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 24px;
}

h5 {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4px;
    margin-bottom: 20px;
}

h6 {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -0.4px;
    margin-bottom: 16px;
}
