.homepage-big-tiles {
    background: linear-gradient(180deg, rgba(107, 69, 188, 0.045) 0%, rgba(171, 224, 193, 0) 100%);
    padding: 75px 0;
    margin-bottom: 6px;

    &__grid {
        max-width: var(--content-wrapper-width);
        margin: 0 auto;
        justify-content: space-between;

        @include from-tablet {
            display: flex;
            gap: 0;
        }
    }

    figure {
        &:last-of-type {
            margin-top: 60px;

            @include from-tablet {
                margin-top: 80px;
            }

            @include desktop {
                margin-top: 120px;
            }
        }

        a {
            display: block;
            text-align: center;
        }

        img {
            max-width: 100%;
            max-height: 500px;
        }
    }

    figcaption,
    &__color {
        font-family: $font-primary;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: $purple80;
        margin: 0 auto;
        font-weight: 600;
    }

    figcaption {
        max-width: 280px;
        margin: -10px auto 0;

        @include from-tablet {
            margin: -20px auto 0;
        }

        @include desktop {
            line-height: 44px;
            margin: -30px auto 0;
            max-width: unset;
        }
    }

    &__color {
        color: $default-secondary-base-color;
        display: inline;
    }
}
