@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --reset-attributes-value-background: #{$purple5};
    --reset-attributes-value-border-radius: 100px;
    --reset-attributes-label-color: #{$purple40};
    --reset-attributes-text-color: #{$purple50};
    --reset-attributes-icon-color: #{$purple50};
}

.ResetAttributes {
}
