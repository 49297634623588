@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --default-notification-height: 40px;
    --notification-info-color: #{$white};
    --notification-info-background: #{$blue30};
    --notification-info-height: var(--default-notification-height);
    --notification-error-color: #{$white};
    --notification-error-background: #{$red50};
    --notification-error-height: var(--default-notification-height);
    --notification-success-color: #{$white};
    --notification-success-background: #{$green50};
    --notification-success-height: var(--default-notification-height);
    --notification-border-radius: 10px;
}
.Notification {
    border-radius: var(--notification-border-radius);
    padding: 8px;

    svg {
        margin-right: 12px;
    }

    &-Text {
        font-family: $font-primary;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.4px;
        margin: 0;
    }
}
