@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --reset-attributes-value-background: #{$grey-light};
    --reset-attributes-value-border-radius: 5px;
    --reset-attributes-label-color: #{$grey3};
    --reset-attributes-text-color: #{$default-primary-base-color};
    --reset-attributes-icon-color: #{$default-primary-base-color};
}

.ResetAttributes {
    &-Attribute {
        margin-right: 10px;
    }

    &-AttributeValue {
        background: var(--reset-attributes-value-background);
        padding: 7px 36px 7px 12px;
        border-radius: var(--reset-attributes-value-border-radius);
        position: relative;
        cursor: pointer;

        @include desktop {
            margin: 0 0 10px;
        }

        @include mobileAndTablet {
            margin: 13px 0 0;
        }
    }

    &-AttributeLabel {
        font-size: 13px;
        font-weight: 400;
        color: var(--reset-attributes-label-color);
        margin-right: 10px;
        font-family: $font-primary;
    }

    &-AttributeText {
        inset-inline-start: 0;
        font-size: 13px;
        font-weight: 700;
        color: var(--reset-attributes-text-color);
        font-family: $font-primary;
    }

    &-CloseIcon {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 36px;

        .CloseIcon {
            width: 24px;
            height: 24px;
            fill: var(--reset-attributes-icon-color);
        }
    }
}
