@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

div:not(.HomePage) > .CmsPage {
    --cms-page-heading-color: #{$default-primary-base-color};
    @include mobile {
        margin-top: 0;
    }
    .CmsPage-Wrapper {
        margin-top: 10px;
        padding-left: var(--content-padding);
        padding-right: var(--content-padding);
        max-width: 1360px;
        @include mobile {
            margin-top: 10px;
        }
    }
    .CmsPage-Heading {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 42px;
        line-height: 130%;
        color: var(--cms-page-heading-color);
        padding-bottom: 20px;
        border-bottom: 1px solid $grey5;

        @include mobile {
            display: block;
            font-size: 30px;
            line-height: 140%;
            letter-spacing: 0.03em;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4 {
        font-family: $font-primary;
        font-weight: bold;
        color: var(--cms-page-heading-color);
        margin-top: 40px;
    }

    h1,
    .h1 {
        font-size: 40px;
        line-height: 150%;
        letter-spacing: 0.03em;
        margin-bottom: 35px;

        @include mobile {
            font-size: 32px;
            line-height: 145%;
            letter-spacing: 0.07em;
        }
    }

    h2,
    .h2 {
        font-size: 30px;
        line-height: 140%;
        letter-spacing: 0.03em;
        margin-bottom: 25px;

        @include mobile {
            font-size: 22px;
            line-height: 135%;
            letter-spacing: 0.07em;
        }
    }
    h3,
    .h3 {
        font-size: 22px;
        line-height: 135%;
        letter-spacing: 0.07em;
        margin-bottom: 17px;

        @include mobile {
            font-size: 18px;
            line-height: 140%;
        }
    }
    h4,
    .h4,
    h5,
    h6 {
        font-size: 15px;
        line-height: 140%;
        letter-spacing: 0.07em;
        margin-bottom: 17px;

        @include mobile {
            font-size: 13px;
            line-height: 150%;
            letter-spacing: 0.05em;
        }
    }

    .CmsPage-Content {
        .columns-layout-2,
        .columns-layout-3 {
            margin-bottom: 45px;
            @include from-tablet {
                display: flex;
                align-items: flex-start;
                grid-gap: 26px;
            }
            > div {
                flex: 1;
            }
            &.center > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                @include mobile {
                    margin-bottom: 35px;
                }
            }
        }
        .with-padding {
            @include desktop {
                padding: 0 5%;
            }
        }

        .cms-button {
            --button-height: 40px;
            --button-hover-height: 40px;
            --button-border-radius: 5px;
            --button-hover-padding: 48px;
            --button-hover-border-radius: 5px;
            background: $default-secondary-base-color;
            color: $white;
            text-align: center;
            letter-spacing: 1px;
            font-family: $font-primary;
            font-weight: bold;
            font-size: 13px;
            line-height: 22px;
            padding: 9px 48px;
        }
        .cards {
            h3 {
                margin-top: 0;
            }

            > div {
                @include mobile {
                    margin-bottom: 40px;
                }
            }
        }
        .cards-with-bg {
            figure {
                @include mobile {
                    margin-bottom: 40px;
                }
            }
            figcaption {
                background: #f9f9f9;
                border-radius: 10px;
                padding: 30px;
                text-align: center;
                margin-top: -10px;
                h5 {
                    margin-top: 0;
                    letter-spacing: 0.07em;
                    font-weight: bold;
                    font-size: 22px;
                    line-height: 135%;
                    font-family: $font-primary;
                }
                p {
                    margin-bottom: 30px;
                }
            }
            img {
                border-radius: 10px 10px 0 0;
            }
            .Button {
                width: auto;
            }
        }

        h2 {
            @include mobile {
                text-align: left;
            }
        }

        .text,
        p:not(.ProductCard-Sku):not(.ProductCard-Name) {
            font-family: $font-secondary;
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
            color: $grey3;
            margin: 0;
        }
        ul:not(.ProductListPage),
        ol {
            li {
                margin: 0;
                color: $grey3;
                font-family: $font-secondary;
                font-weight: normal;
                font-size: 15px;
                line-height: 200%;
            }
        }

        ul:not(.ProductListPage),
        ol {
            margin-left: 20px;
            li {
                &:before {
                    display: none;
                }
            }
        }

        ul:not(.ProductListPage) {
            list-style: disc;
            li {
                list-style: inherit;
            }
        }

        ol {
            list-style: decimal;
            li {
                list-style: inherit;
            }
        }

        table {
            overflow-x: auto;
            width: 100% !important;

            th,
            td {
                word-break: break-word;
            }

            thead tr th {
                padding: 13px 23px;

                @include mobile {
                    padding: 13px 8px;
                }
            }

            tbody tr {
                th {
                    text-align: center;
                    background: var(--table-head-background);
                }

                td {
                    padding: 20px 30px;
                    font-family: $font-secondary;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 150%;
                    color: $black;
                    border-right: 1px solid $grey5;

                    @include mobile {
                        padding: 20px 0 20px 8px;
                    }

                    &:first-of-type {
                        color: var(--cms-page-heading-color);
                        font-weight: 500;
                        letter-spacing: 0.05em;
                        @include mobile {
                            padding: 20px 20px 20px 0;
                        }
                    }

                    &:last-of-type {
                        border-right: none;

                        @include mobile {
                            padding: 20px 0 20px 8px;
                        }
                    }
                }
            }
            thead th {
                &:first-of-type:not(:last-of-type) {
                    border-radius: 7px 0 0 7px;
                }
                &:last-of-type:not(:first-of-type) {
                    border-radius: 0 7px 7px 0;
                }
            }
        }
        .Accordion {
            h5 {
                margin: 0;
            }
            &-Box {
                height: 65px;
                padding: 20px 30px;

                @include mobile {
                    padding: 20px 0;
                }

                &_isOpen {
                    height: 100%;
                    padding: 20px 30px 30px;
                    @include mobile {
                        padding: 20px 0 30px;
                    }
                    .Accordion-Header {
                        color: $default-secondary-base-color;

                        &::after {
                            border-color: $default-secondary-base-color;
                        }
                    }
                }
                &:first-of-type {
                    border-top: 0;
                }
            }

            &-Text {
                color: $grey3;
                font-family: $font-secondary;
                font-weight: normal;
                font-size: 15px;
                line-height: 150%;
            }
        }
    }
}
