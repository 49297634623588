@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --advox-free-delivery-icon-color: #{$default-primary-base-color};
}

.AdvoxFreeDelivery {
    padding: 12px;

    &-Value,
    &-Text {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        color: $grey3;
        white-space: break-spaces;
    }

    &-Text {
        margin: 0;
        display: flex;
        align-items: center;
        svg {
            margin-right: 17px;
            width: 23px;
            height: 17px;
            path {
                stroke: var(--advox-free-delivery-icon-color);
            }
        }
    }
}
