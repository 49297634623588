@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

.SearchItem {
    &:first-of-type {
        margin-top: 5px;
    }

    &-Wrapper {
        grid-template-columns: 70px calc(100% - 82px);
        transition: background-color 0.3s ease-in-out;

        @include mobile {
            grid-template-columns: 50px calc(100% - 62px);
        }

        &:hover {
            background-color: $grey10;
            .SearchItem-Title {
                color: $default-secondary-base-color;
            }
        }
    }

    &-Content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 10px;
    }

    &-Title {
        font-size: 18px;
        margin-bottom: 0;
        color: $default-primary-base-color;
        padding-right: 15px;
        transition: color 0.3s ease-in-out;

        @include mobile {
            font-size: 14px;
        }
    }

    &-Price {
        .ProductPrice-PriceValue {
            font-size: 14px;
            line-height: 1;
        }
    }
}
