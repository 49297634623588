@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

//mergo
:root {
    --newsletter-background: #{$purple50};
    --newsletter-agreement-link-color: #{$orange50};
    --consents-button-color: #{$default-secondary-base-color};
}

.Newsletter {
    .NewsletterSubscription {
        .Button {
            --button-border-radius: 100px;
        }
    }

    &-Background {
        @include desktop {
            width: 479px;
            height: 209px;
            position: absolute;
            left: 0;
            transform: translateX(calc(-50% + 40px));
        }
    }

    &-Info {
        span {
            color: $orange50;
        }

        p {
            &:nth-of-type(1) {
                font-family: var(--font-palanquin);
                font-size: 32px;
                font-weight: 700;
                line-height: 44px;
                margin: 0 0 10px;
                text-transform: inherit;
                max-width: fit-content;

                @include mobile {
                    padding: 0 20px;
                }
            }
        }
    }

    &-Figure {
        pointer-events: none;
    }
}
